import { Col, Row, message } from 'antd';
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { addTiket, fetchOneTiket, updateTiket } from 'redux/features/tiket';
import { MyEditor } from '../../../components/Editor'
import { fetchAllCategory } from 'redux/features/category';
import ImageUploader from 'components/shared-components/ImageUploader';
import { getUserProfile } from 'redux/features/auth';
import moment from 'moment';

export const ADD_CONTENT = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation()
  const [profile, setProfile] = useState(0);

  const [body, setBody] = useState("")
  const [template, setTemplate] = useState("")
  const [data, setData] = useState({})
  const [categories, setCategories] = useState();
  const [user_id, setUserId] = useState("");
  const [role, setRole] = useState("user")
  const [imgUrl, setImgUrl] = useState("");

  const getProfile = async () => {
    try {
      const response = await dispatch(getUserProfile()).unwrap();
      form.setFieldsValue({
        ...response.data.user
      });
      setUserId(response.data.user.id)
      setProfile(response.data.user)
      setRole(response.data.user.role_id)
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const onFinish = (values) => {
    // if (location?.state?.id) {
    //   updateData(values)
    // } else {
    //   createData(values)
    // }
    createData(values)
  }

  const createData = async (values) => {
    try {
      await dispatch(addTiket({
        "no_tiket": "BELUM ADA",
        "nomor_surat": "BELUM ADA",
        "file": "BELUM ADA",
        "title": values.title,
        "username": values.nik,
        "published_date": moment().format(),
        "expired_date":moment().format(),
        "description": values.description,
        "status": "OPEN",
        "body": body
      })).unwrap()
      history.push("/app/tiket")
    } catch (error) {
      console.log(values)
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updateTiket({
        "user_id": 1,
        "id":location?.state?.id,
        "title": values.title,
        "thumbnail": imgUrl,
        "description": values.description,
        "category_id": values.category,
        "file": values.file,
        "body": body
      })).unwrap()
      history.push("/app/contents")
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneTiket(id)).unwrap()
      form.setFieldsValue({
        ...data.data,
        category: data.data.category_id,
        title:location.state.title
      });
      setData(data?.data)
      setBody(data?.data.body)
      setImgUrl(data.data.thumbnail)
    } catch (err) {
      console.log(err)
    }
  }

  const getCategories = async () => {
    try {
        const response = await dispatch(fetchAllCategory()).unwrap()
        setCategories(response.data.map(category => {
          return {
            value: category.id,
            label: category.name
          }
        }))
    } catch (error) {
        message.error(error?.message || 'Failed to fetch data')
    }
  }

  const handleImageUpload = (info) => {

    if (info.file.status === 'uploading') {
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setImgUrl(info.file.response.secure_url)
    }
  }

  useEffect(() => {
    if (location?.state?.id) {
      getDataById(location?.state?.id)
    }
    form.setFieldsValue({
      title:location.state.title
    });
    getCategories();
    getProfile()
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Pembuatan Surat {location.state.title}</h2>
          <p>Buat surat baru untuk keperluan masyarakat</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Title"
                name="title"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Nama Lengkap"
                name="nama_lengkap"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="NIK"
                name="nik"
              >
                <Input disabled />
              </Form.Item>

              {/* <Form.Item
                label="Nomor Surat"
                name="nomor_surat"
              >
                <Input disabled />
              </Form.Item> */}

              <Form.Item>
                <MyEditor data={template} setState={setTemplate} ></MyEditor>
              </Form.Item>

              <Form.Item

              >
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  Buat Surat Baru
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ADD_CONTENT