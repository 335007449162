import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import URLS from 'redux/urls'
import request from 'redux/utils/request'

export const fetchAllTiket = createAsyncThunk(
    'Tiket/fetchAllTiket',
    async (params, { rejectWithValue }) => {
        console.log("FETCH ALL Tiket", params)
        try {
            const response = await request('get', URLS.TIKET, params)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addTiket = createAsyncThunk(
    'Tiket/addTiket',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('post', URLS.TIKET, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateTiket = createAsyncThunk(
    'Tiket/updateTiket',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await request('patch', `${URLS.TIKET}/${payload.id}`, payload)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const fetchOneTiket = createAsyncThunk(
    'Tiket/fetchOneTiket',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('get', `${URLS.TIKET}/${id}`)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteTiket = createAsyncThunk(
    'Tiket/deleteTiket',
    async (id, { rejectWithValue }) => {
        try {
            const response = await request('delete', `${URLS.TIKET}/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const initialState = {
    loading: {
        query: false,
        mutation: false
    },
    filter: {
        q: ''
    },
    list: [],
    count: {},
    selected: {},
    selectedRows: []
}

const loadingReducer = (fieldName, status) => (state) => {
    state.loading[fieldName] = status
}

const startLoadingQuery = loadingReducer('query', true)
const stopLoadingQuery = loadingReducer('query', false)
const startLoadingMutation = loadingReducer('mutation', true)
const stopLoadingMutation = loadingReducer('mutation', false)

export const TiketSlice = createSlice({
    name: 'Tiket',
    initialState,
    reducers: {
        setAppliedSearchText: (state, action) => {
            state.filter.q = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllTiket.pending, startLoadingQuery)
            .addCase(fetchAllTiket.fulfilled, (state, action) => {
                state.list = action.payload
                state.loading.query = false
            })
            .addCase(fetchAllTiket.rejected, stopLoadingQuery)
        builder
            .addCase(fetchOneTiket.pending, startLoadingQuery)
            .addCase(fetchOneTiket.rejected, stopLoadingQuery)
            .addCase(fetchOneTiket.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(updateTiket.pending, startLoadingQuery)
            .addCase(updateTiket.rejected, stopLoadingQuery)
            .addCase(updateTiket.fulfilled, (state, action) => {
                state.loading.query = false
                state.selected = action.payload
            })
        builder
            .addCase(deleteTiket.pending, startLoadingMutation)
            .addCase(deleteTiket.fulfilled, stopLoadingMutation)
            .addCase(deleteTiket.rejected, stopLoadingMutation)
    }
});


export const { setSelectedRows, setAppliedSearchText } = TiketSlice.actions;

export default TiketSlice.reducer;