import { strings } from 'res';
import { DashboardIcon, InsightIcon, Megaphone, OrderIcon, ReportIcon, SLAIcon } from "../assets/svg/icon";
import { UserOutlined, DownCircleOutlined, InfoCircleOutlined, BookOutlined, SettingOutlined, PaperClipOutlined, DatabaseOutlined, DatabaseTwoTone } from '@ant-design/icons';
import { reguler_access, admin, staff, layanan_umum, tiket } from "../privileges/roles"

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Konten",
      path: strings.navigation.path.contents,
      title: "Konten",
      icon: ReportIcon,
      access: admin,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Layanan",
      path: strings.navigation.path.layanan,
      title: "Layanan",
      icon: InsightIcon,
      access: admin,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Data Kelurahan",
      path: strings.navigation.path.data_kelurahan,
      title: "Data Kelurahan",
      icon: DatabaseOutlined,
      access: admin,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Tiket",
      path: strings.navigation.path.tiket,
      title: "Tiket",
      icon: PaperClipOutlined,
      access: tiket,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Layanan Umum",
      path: strings.navigation.path.layanan_umum,
      title: "Layanan Umum",
      icon: InsightIcon,
      access: layanan_umum,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Data Kelurahan",
      path: strings.navigation.path.detail_data_kelurahan,
      title: "Data Kelurahan",
      icon: DatabaseTwoTone,
      access: admin,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Berita",
      path: strings.navigation.path.berita,
      title: "Berita",
      icon: InfoCircleOutlined,
      access: admin,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Unduhan",
      path: strings.navigation.path.unduhan,
      title: "Unduhan",
      icon: DownCircleOutlined,
      breadcrumb: false,
      access: admin,
      submenu: []
    },
    {
      key: "Pengumuman",
      path: strings.navigation.path.pengumuman,
      title: "Pengumuman",
      icon: Megaphone,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Kelurahan",
      path: strings.navigation.path.kelurahan,
      title: "Kelurahan",
      icon: SLAIcon,
      access: admin,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: "Categories",
    //   path: strings.navigation.path.categories,
    //   title: "Categories",
    //   icon: OrderIcon,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: "Pengguna",
      path: strings.navigation.path.users,
      title: "Pengguna",
      icon: UserOutlined,
      access: staff,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Laporan",
      path: strings.navigation.path.laporan,
      title: "Laporan",
      icon: BookOutlined,
      access: staff,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const ControlTree = [{
  key: "Akun",
  path: strings.navigation.path.settings,
  title: "Akun",
  breadcrumb: false,
  submenu: [
    {
      key: "Pengaturan",
      path: strings.navigation.path.settings,
      title: "Pengaturan",
      icon: SettingOutlined,
      access: reguler_access,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...ControlTree,
]

export default navigationConfig;