import {
  Button,
  Card,
  Col,
  message,
  Row,
  Table,
  Input,
  Modal,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "res";
import { fetchAllTiket, deleteTiket } from "redux/features/tiket";
import moment from "moment";
import { fetchAllCategory } from "redux/features/category";
import { filter } from "lodash";

const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Ok"
        cancelText="Cancel"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>
    </>
  );
};

export const CONTENTS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState({});
  const [modal, contextHolder] = Modal.useModal();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "-title",
  });

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "NIK",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Tanggal Dibuat",
      dataIndex: "published_date",
      key: "published_date",
      render: (_, record) =>
        record.published_date
          ? moment(record.published_date).format("DD-MM-YYYY")
          : "-",
    },
    {
      title: "Berlaku Sampai",
      dataIndex: "expired_date",
      key: "expired_date",
      render: (_, record) =>
        record.expired_date ? moment(record.published_date).format("DD-MM-YYYY") : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if (record.status === "OPEN") {
          return (
            <div className="text-center">
              <Button
                type="primary"
                style={{ textAlign: "center" }}
              >
                OPEN
              </Button>
            </div>
          )
        }
      },
    },
    {
      title: () => <div className="text-center">Detail</div>,
      key: "detail",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="primary"
            style={{ textAlign: "center" }}
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_content}`,
                state: record,
              });
            }}
          >
            DETAIL
          </Button>
        </div>
      ),
    },
    // {
    //   title: () => <div className="text-center">Action</div>,
    //   key: "status",
    //   render: (_, record) => (
    //     <div className="text-center">
    //       <Button
    //         type="danger"
    //         style={{ textAlign: "center", color: "white" }}
    //         onClick={() => {
    //           confirm(record.id);
    //         }}
    //       >
    //         Delete
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const getData = async (params) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchAllTiket(params)).unwrap();
      setData(response.data);
      setMetaData(response.meta.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const getCategories = async () => {
    try {
      const response = await dispatch(fetchAllCategory()).unwrap();
      setCategories(
        response.data.map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        })
      );
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure wanna delete this?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await dispatch(deleteTiket(id));
        getData(filters);
      },
      onCancel: () => { },
    });
  };

  const handleCategorySelect = (val) => {
    setFilters({ ...filters, category_id: val });
  };

  useEffect(() => {
    getData(filters);
    getCategories();
  }, []);

  useMemo(() => {
    getData(filters);
  }, [filters]);

  const handlePageChange = (page) => {
    setFilters({ ...filters, page: page.current });
  };

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Tiket Surat</h2>
          <p>Semua layanan surat anda yang sedang diproses</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Select
              placeholder="Filter by category"
              onSelect={handleCategorySelect}
              options={categories}
              className="mb-4"
              allowClear
              onClear={handleCategorySelect}
            />
            <Table
              className="no-border-last"
              style={{ textAlign: "center", margin: "auto" }}
              columns={tableColumns}
              dataSource={data}
              rowKey="id"
              loading={isLoading}
              pagination={{
                defaultCurrent: 1,
                total: metaData.total_data,
              }}
              onChange={handlePageChange}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            type="primary"
            style={{ border: "0px" }}
            htmlType="submit"
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.buat_surat}`,
              });
            }}
            block
          >
            Buat Tiket Baru
          </Button>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default withRouter(CONTENTS);
