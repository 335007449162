import { Col, Row, message } from 'antd';
import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import { useDispatch } from 'react-redux';
import { fetchAllCategory } from 'redux/features/category';
import { fetchAllContent } from 'redux/features/content';
import { getUserProfile } from 'redux/features/auth';
import LayananUmum from '../layanan-umum';

export const DefaultDashboard = () => {

  const dispatch = useDispatch();
  const [profile, setProfile] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [tiketCount, setTiketCount] = useState(0);
  const [pengumuman, setPengumuman] = useState(0);
  const [contentCount, setContentCount] = useState(0);
  const [user_id, setUserId] = useState("");
  const [role, setRole] = useState("user")

  const getProfile = async () => {
    try {
      const response = await dispatch(getUserProfile()).unwrap();
      setUserId(response.data.user.id)
      setProfile(response.data.user)
      setRole(response.data.user.role_id)
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const getAllCategory = async () => {
    try {
      const response = await dispatch(fetchAllCategory()).unwrap()
      setCategoryCount(response.data.length)
    } catch (error) {
        message.error(error?.message || 'Failed to fetch data')
    }
  }

  const getAllContent = async (params) => {
    try {
      const response = await dispatch(fetchAllContent(params)).unwrap()
      setContentCount(response.data.length)
    } catch (error) {
        message.error(error?.message || 'Failed to fetch data')
    }
  }

  useEffect(() => {
    const filters = {
      page: 1,
      limit: 10,
      sort: "created_at"
    }
    getProfile()
    getAllCategory()
    getAllContent(filters)
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Selamat Datang Bpk/Ibu {profile.nama_lengkap}</h2>
          <p>Pelayanan pembuatan surat kecamatan 1 pintu</p>
        </Col>

      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Layanan`}
                value={contentCount}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Tiket Aktif`}
                value={tiketCount}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Pengumuman Bulan Ini`}
                value={pengumuman}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Surat Keluar`}
                value={categoryCount}
              />
            </Col>
          </Row>
          <Row>
            <LayananUmum></LayananUmum>
          </Row>
        </Col>
      </Row>
    </>
  )
}


export default withRouter(DefaultDashboard);
