import { Col, Row } from 'antd';
import React from "react";
import { Button, Card, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUser, fetchOneUser, updateUser } from 'redux/features/user';
import { getUserProfile } from 'redux/features/auth';

export const SETTINGS = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();
  const [company, setCompany] = useState("");
  const [user_id, setUserId] = useState("");
  const [role, setRole] = useState("user")

  const getProfile = async () => {
    try {
      const response = await dispatch(getUserProfile()).unwrap();
      form.setFieldsValue({
        ...response.data.user
      });
      console.log(response.data.user)
      setUserId(response.data.user.id)
      setRole(response.data.user.role_id)
      setCompany(response.data.user.perusahaan)
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const handleChange = (value) => {
    setRole(value)
  };

  const onFinish = (values) => {
    updateData(values)
  }

  const updateData = async (values) => {
    try {
      await dispatch(updateUser({
        ...values,
        id: user_id,
        no_phone: values.no_phone,
        nama_lengkap: values.nama_lengkap,
        nik: values.nik,
        password: values.password
      })).unwrap()
      // history.push("/app/users")
      message.success("Data berhasil diubah!")
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Pengaturan</h2>
          <p>Summary analisa data aplikasi kali ini</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              onFinish={onFinish}
              form={form}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Nama Lengkap"
                name="nama_lengkap"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="NIK"
                name="nik"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="No Phone"
                name="no_phone"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Alamat Lengkap"
                name="alamat_lengkap"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Alamat Domisili"
                name="alamat_domisili"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Kelurahan"
                name="kelurahan"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Username"
                name="username"

              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item

              >
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default SETTINGS