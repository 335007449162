import { Button, Card, Col, message, Row, Table, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { strings } from "res";
import { fetchAllContent, deleteContent } from "redux/features/content";
import moment from "moment";

const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="确认"
        cancelText="取消"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>
    </>
  );
};

export const CONTENTS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [modal, contextHolder] = Modal.useModal();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "created_at",
    category_id: 10,
  });

  const getData = async (params) => {
    try {
      setLoading(false);
      const response = await dispatch(fetchAllContent(params)).unwrap();
      setData(response.data);
      setMetaData(response.meta.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure wanna delete this?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await dispatch(deleteContent(id));
        getData(filters);
      },
      onCancel: () => { },
    });
  };

  const handlePageChange = (page) => {
    setFilters({ ...filters, page: page.current });
  };

  useEffect(() => {
    getData(filters);
  }, [filters]);

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Layanan Kecamatan</h2>
          <p>Semua layanan masyarakat tersedia pengerjaan 2-3 hari kerja.</p>
        </Col>
      </Row>
      <Row gutter={24}>

        {data.map(data => {
          return (
            <Col xs={24} sm={24} md={6} lg={6}>
              <Card>
                <img src={data.thumbnail} style={{ width: "100%", margin: "3px", height: "150px" }}></img>
                <h5 style={{ textAlign: 'center' }}>{data.title}</h5>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Button
                      type="primary"
                      style={{ border: "0px" }}
                      htmlType="submit"
                      onClick={() => {
                        history.push({
                          pathname: `${strings.navigation.path.buat_surat}`,
                          state: data
                        });
                      }}
                      block
                    >
                      Buat Surat
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          )
        })}
      </Row>

      {contextHolder}
    </>
  );
};

export default withRouter(CONTENTS);
